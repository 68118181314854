var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volumeSet" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "了机",
            fullscreen: "",
            visible: _vm.modelShow,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.modelShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机台编号:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.seletItem.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.seletItem, "code", $$v)
                                  },
                                  expression: "seletItem.code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "当前品种:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.seletItem.fact.productName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.seletItem.fact,
                                      "productName",
                                      $$v
                                    )
                                  },
                                  expression: "seletItem.fact.productName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "当前批次:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.seletItem.fact.batchName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.seletItem.fact,
                                      "batchName",
                                      $$v
                                    )
                                  },
                                  expression: "seletItem.fact.batchName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划开台时间:" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: "",
                                  type: "datetime",
                                  placeholder: "选择日期时间"
                                },
                                model: {
                                  value: _vm.seletItem.fact.factStartTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.seletItem.fact,
                                      "factStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "seletItem.fact.factStartTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划了机时间:" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: "",
                                  type: "datetime",
                                  placeholder: "选择日期时间"
                                },
                                model: {
                                  value: _vm.seletItem.fact.factEndTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.seletItem.fact,
                                      "factEndTime",
                                      $$v
                                    )
                                  },
                                  expression: "seletItem.fact.factEndTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "实际开台时间:" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: "",
                                  type: "datetime",
                                  placeholder: "选择日期时间"
                                },
                                model: {
                                  value: _vm.seletItem.fact.openTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.seletItem.fact,
                                      "openTime",
                                      $$v
                                    )
                                  },
                                  expression: "seletItem.fact.openTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "实际了机时间:" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择日期时间"
                                },
                                model: {
                                  value: _vm.form.closeTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "closeTime", $$v)
                                  },
                                  expression: "form.closeTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划产量:" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.form.planYield,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "planYield", $$v)
                                    },
                                    expression: "form.planYield"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(
                                      "\n                                    kg\n                                "
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "空锭数:" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.form.spinEmpty,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "spinEmpty", $$v)
                                    },
                                    expression: "form.spinEmpty"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(
                                      "\n                                    个\n                                "
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "设备停机时间:" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.form.num,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "num", $$v)
                                    },
                                    expression: "form.num"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(
                                      "\n                                    H\n                                "
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开台米数:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.seletItem.fact.openYield,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.seletItem.fact,
                                      "openYield",
                                      $$v
                                    )
                                  },
                                  expression: "seletItem.fact.openYield"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "了机米数:", prop: "closeYield" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入了机时设备米数",
                                  type: "number"
                                },
                                on: { change: _vm.erroJiSuan },
                                model: {
                                  value: _vm.form.closeYield,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "closeYield", $$v)
                                  },
                                  expression: "form.closeYield"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "实际产量:", prop: "factYield" }
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    type: "number"
                                  },
                                  on: { change: _vm.erroFi },
                                  model: {
                                    value: _vm.form.factYield,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "factYield", $$v)
                                    },
                                    expression: "form.factYield"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(
                                      "\n                                    kg\n                                "
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "完成率:" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.errorYield,
                                    callback: function($$v) {
                                      _vm.errorYield = $$v
                                    },
                                    expression: "errorYield"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(
                                      "\n                                    %\n                                "
                                    )
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.makeSure } },
                [_vm._v("确认了机")]
              ),
              _c("el-button", { on: { click: _vm.closeModel } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }