<template>
  <div class="volumeSet">
    <el-dialog
        title="呼叫维修"
        fullscreen
        :visible.sync="modelShow"
        width="35%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
      <div class="content">
        <el-form :model="form" label-width="120px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="机台编号:">
                <el-input v-model="form.code" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="维修人员:">
                <el-input v-model="form.code" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="故障大类:">
                <el-select v-model="form.code" placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="故障小类:">
                <el-select v-model="form.code" placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注:">
                <el-input v-model="form.code" resize="none" type="textarea" :rows="6"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="danger" @click="makeSure">呼叫维修</el-button>
        <el-button @click="closeModel">取 消</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "scene",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form:{
        code:1000,
      },
      options:[]
    }
  },
  created(){
    console.log(this)
  },
  methods: {
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      this.$emit('closeModel', false)
    },
  }
}
</script>

<style lang="scss" scoped>
.item_name {
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}
.dialog-footer {
  width: 100%;
  text-align: center;
}
</style>