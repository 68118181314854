<template>
  <div class="volumeSet">
    <el-dialog
        title="批量设置"
        fullscreen
        :visible.sync="modelShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="35%">
      <div class="content">
        <div class="status">
          <el-row>
            <el-col :span="5">
              <span class="item_name">状态</span>
            </el-col>
            <el-col :span="19">
              <el-radio-group v-model="item.status">
                <el-radio-button :disabled="item.status == 2" :label="1"> 开 台 中</el-radio-button>
                <el-radio-button :disabled="item.status == 1" :label="2"> 了 机 中</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </div>
        <div class="operate">
          <el-row>
            <el-col :span="5">
              <span class="item_name">操作</span>
            </el-col>
            <el-col :span="19">
              <el-button @click="changeStatus(item.operate)" :type="item.status == 1?'':'success'"
                         :disabled="item.status == 1">确认开台
              </el-button>
              <el-button @click="changeStatus(item.operate)" :type="item.status == 2?'':'success'"
                         :disabled="item.status == 2">确认了机
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button @click="closeModel">取 消</el-button>
        <el-button type="primary" @click="makeSure">确 定</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "volumeSet",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: ()=> {}
    }
  },
  data() {
    return {}
  },
  methods: {
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      this.$emit('closeModel', false)
    },
    changeStatus(operate){
      console.log(operate)
    }
  }
}
</script>

<style lang="scss" scoped>
.item_name {
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}

/deep/ .el-radio-button {
  margin-right: 13px;
}

/deep/ .el-radio-button__inner {
  border-radius: 4px;
  width: 98px;
}
</style>